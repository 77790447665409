import { ContainerForInputIcon } from "../ContainerForInputIcon";

export const Telegram = () => {
  return (
    <ContainerForInputIcon>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0765 1.93649C15.7014 1.55145 15.1462 1.408 14.6284 1.559L2.556 5.06964C2.00977 5.22139 1.62262 5.65702 1.51832 6.21041C1.41178 6.77363 1.78393 7.48859 2.27013 7.78756L6.04493 10.1076C6.43208 10.3454 6.93179 10.2858 7.25217 9.96265L11.5747 5.61323C11.7923 5.38673 12.1524 5.38673 12.37 5.61323C12.5876 5.83217 12.5876 6.18701 12.37 6.4135L8.03999 10.7637C7.71886 11.0861 7.65884 11.5881 7.89518 11.9777L10.2016 15.7903C10.4717 16.2433 10.9369 16.5 11.4471 16.5C11.5072 16.5 11.5747 16.5 11.6347 16.4924C12.22 16.417 12.6851 16.0168 12.8577 15.4506L16.4367 3.39359C16.5942 2.88021 16.4517 2.32153 16.0765 1.93649Z"
          fill="#3F260A"
        />
      </svg>
    </ContainerForInputIcon>
  );
};
