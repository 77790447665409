import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const Dishwasher = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8693 0H4.11768C3.2688 0 2.57812 0.690674 2.57812 1.53955V15.6006C2.57812 15.7949 2.7356 15.9521 2.92969 15.9521C3.12396 15.9521 3.28125 15.7949 3.28125 15.6006V5.08887H20.7057V20.1691C20.7057 20.6303 20.3306 21.0055 19.8693 21.0055H4.11768C3.65643 21.0055 3.28125 20.6303 3.28125 20.1691V17.0067C3.28125 16.8124 3.12396 16.6551 2.92969 16.6551C2.73541 16.6551 2.57812 16.8124 2.57812 17.0067V20.1691C2.57812 20.9412 3.14941 21.5823 3.89154 21.692V23.3672C3.89154 23.7162 4.17554 24 4.52435 24H5.75995C6.02216 24 6.26019 23.8352 6.35266 23.5895L6.54565 23.0748H17.3719L17.5649 23.5898C17.6572 23.8352 17.8954 24 18.1575 24H19.4625C19.8115 24 20.0953 23.7162 20.0953 23.3672V21.692C20.8374 21.5823 21.4089 20.9412 21.4089 20.1691V1.53955C21.4089 0.690674 20.7182 0 19.8693 0ZM18.2062 23.2969L17.9899 22.7201C17.9112 22.5115 17.7083 22.3715 17.4853 22.3715H6.43176C6.20911 22.3715 6.00659 22.5115 5.92731 22.721L5.71143 23.2969H4.59467V21.7086H19.3922V23.2969H18.2062ZM3.28125 4.38574V1.53955C3.28125 1.07831 3.65643 0.703125 4.11768 0.703125H19.8693C20.3306 0.703125 20.7057 1.07831 20.7057 1.53955V4.38574H3.28125Z"
          fill="#583711"
        />
        <path
          d="M19.6511 9.54547C19.8452 9.54547 20.0026 9.38818 20.0026 9.19391V6.14355C20.0026 5.94946 19.8452 5.79199 19.6511 5.79199H4.33594C4.14185 5.79199 3.98438 5.94946 3.98438 6.14355V19.9508C3.98438 20.1451 4.14185 20.3024 4.33594 20.3024H19.6511C19.8452 20.3024 20.0026 20.1451 20.0026 19.9508V10.6002C20.0026 10.4061 19.8452 10.2486 19.6511 10.2486C19.4568 10.2486 19.2995 10.4061 19.2995 10.6002V19.5992H4.6875V6.49512H19.2995V9.19391C19.2995 9.38818 19.4568 9.54547 19.6511 9.54547Z"
          fill="#583711"
        />
        <path
          d="M13.5403 1.46631H10.4465C10.1494 1.46631 9.90747 1.70819 9.90747 2.00537V3.0835C9.90747 3.38068 10.1494 3.62256 10.4465 3.62256H13.5403C13.8376 3.62256 14.0793 3.38068 14.0793 3.0835V2.00537C14.0793 1.70819 13.8376 1.46631 13.5403 1.46631ZM13.3762 2.91943H10.6106V2.16943H13.3762V2.91943Z"
          fill="#583711"
        />
        <path
          d="M16.2708 2.19287H15.7551C15.561 2.19287 15.4036 2.35034 15.4036 2.54443C15.4036 2.73871 15.561 2.896 15.7551 2.896H16.2708C16.465 2.896 16.6223 2.73871 16.6223 2.54443C16.6223 2.35034 16.465 2.19287 16.2708 2.19287Z"
          fill="#583711"
        />
        <path
          d="M8.23169 2.19287H7.71606C7.52197 2.19287 7.3645 2.35034 7.3645 2.54443C7.3645 2.73871 7.52197 2.896 7.71606 2.896H8.23169C8.42596 2.896 8.58325 2.73871 8.58325 2.54443C8.58325 2.35034 8.42596 2.19287 8.23169 2.19287Z"
          fill="#583711"
        />
        <path
          d="M9.79822 10.8245C9.85315 10.6882 9.83685 10.5341 9.75464 10.4121C9.67206 10.2896 9.53455 10.2166 9.38696 10.2166H6.08758C5.94 10.2166 5.80249 10.2896 5.71991 10.4123C5.63769 10.5342 5.6214 10.6884 5.67651 10.8246C5.97333 11.5582 6.62317 12.0659 7.38562 12.1864V14.0081C6.57959 14.1714 5.97095 14.8856 5.97095 15.7392V17.9324C5.97095 18.2271 6.21045 18.4666 6.50507 18.4666H8.96948C9.2641 18.4666 9.5036 18.2271 9.5036 17.9324V15.7392C9.5036 14.8856 8.89496 14.1714 8.08874 14.0081V12.1864C8.85138 12.0659 9.50122 11.558 9.79822 10.8245ZM6.53345 10.9197H8.94128C8.65857 11.2877 8.21857 11.5107 7.73737 11.5107C7.25598 11.5107 6.81598 11.2877 6.53345 10.9197ZM8.80048 15.7392V17.7634H6.67407V15.7392C6.67407 15.1531 7.15106 14.6761 7.73737 14.6761C8.32367 14.6761 8.80048 15.1531 8.80048 15.7392Z"
          fill="#583711"
        />
        <path
          d="M14.4533 18.4667C16.5978 18.4667 18.3427 16.7219 18.3427 14.5773C18.3427 12.4326 16.5978 10.688 14.4533 10.688C12.3088 10.688 10.564 12.4326 10.564 14.5773C10.564 16.7219 12.3088 18.4667 14.4533 18.4667ZM14.4533 11.3911C16.2102 11.3911 17.6395 12.8204 17.6395 14.5773C17.6395 16.3342 16.2102 17.7635 14.4533 17.7635C12.6964 17.7635 11.2671 16.3342 11.2671 14.5773C11.2671 12.8204 12.6964 11.3911 14.4533 11.3911Z"
          fill="#583711"
        />
        <path
          d="M14.4534 16.3972C15.4568 16.3972 16.2733 15.5807 16.2733 14.5771C16.2733 13.5735 15.4568 12.7571 14.4534 12.7571C13.4498 12.7571 12.6333 13.5735 12.6333 14.5771C12.6333 15.5807 13.4498 16.3972 14.4534 16.3972ZM14.4534 13.4602C15.0692 13.4602 15.5701 13.9612 15.5701 14.5771C15.5701 15.1929 15.0692 15.6939 14.4534 15.6939C13.8374 15.6939 13.3364 15.1929 13.3364 14.5771C13.3364 13.9614 13.8374 13.4602 14.4534 13.4602Z"
          fill="#583711"
        />
        <path
          d="M15.3201 7.6394C15.3201 7.44513 15.1627 7.28784 14.9686 7.28784H9.01855C8.82446 7.28784 8.66699 7.44513 8.66699 7.6394C8.66699 7.8335 8.82446 7.99097 9.01855 7.99097H14.9686C15.1627 7.99097 15.3201 7.8335 15.3201 7.6394Z"
          fill="#583711"
        />
      </svg>
    </ContainerForFeatures>
  );
};
