export const GPay = () => {
  return (
    <svg viewBox="0 0 62 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.658 17.3285V22.1736H28.1023V10.2703H32.171C33.2081 10.2703 34.0856 10.6092 34.8036 11.2871C35.5216 11.965 35.8806 12.8025 35.8806 13.7994C35.8806 14.7963 35.5216 15.6337 34.8036 16.3117C34.0856 16.9896 33.2081 17.3285 32.171 17.3285H29.658ZM29.658 11.7058V15.8929H32.2308C32.8291 15.8929 33.3278 15.6736 33.7266 15.235C34.1255 14.8362 34.325 14.3577 34.325 13.7994C34.325 13.2411 34.1255 12.7626 33.7266 12.3638C33.3278 11.9252 32.8291 11.7058 32.2308 11.7058H29.658ZM39.4706 13.7396C40.5875 13.7396 41.485 14.0387 42.1631 14.6368C42.8014 15.235 43.1205 16.0724 43.1205 17.1491V22.1736H41.6247V21.0371H41.5648C40.9266 21.9543 40.0889 22.4129 39.0518 22.4129C38.1343 22.4129 37.3765 22.1537 36.7781 21.6353C36.1798 21.1169 35.8806 20.4589 35.8806 19.6613C35.8806 18.784 36.1998 18.1061 36.838 17.6276C37.4762 17.1092 38.3338 16.85 39.4108 16.85C40.3283 16.85 41.0662 17.0294 41.6247 17.3883V17.0294C41.6247 16.511 41.4252 16.0525 41.0263 15.6537C40.6075 15.2948 40.069 15.1153 39.5305 15.1153C38.6928 15.1153 38.0147 15.4742 37.4961 16.192L36.12 15.3546C36.8779 14.2779 37.9947 13.7396 39.4706 13.7396ZM37.4363 19.6613C37.4363 20.0601 37.6158 20.3991 37.9748 20.6782C38.2939 20.9574 38.6928 21.0969 39.1715 21.0969C39.8097 21.0969 40.3881 20.8577 40.9066 20.3791C41.3853 19.9006 41.6247 19.3224 41.6247 18.6445C41.146 18.2856 40.4878 18.1061 39.6501 18.1061C39.0119 18.1061 38.4934 18.2656 38.0945 18.5847C37.6557 18.8638 37.4363 19.2227 37.4363 19.6613ZM51.557 14.0387L46.4113 25.7626H44.8556L46.7105 21.6353L43.3598 14.0387H45.0351L47.4883 19.9006L49.8816 14.0387H51.557Z"
        fill="#3F260A"
      />
      <path
        d="M21.4006 21.3357H19.1868V19.6011C19.9048 19.1225 20.3834 18.4047 20.5629 17.5673H16.8533V14.9354H23.3153C23.395 15.3741 23.4349 15.8327 23.4349 16.3112C23.4349 18.4047 22.6571 20.1394 21.4006 21.3357Z"
        fill="#3F260A"
      />
      <path
        d="M10.6907 19.2426V17.4481H12.9643C13.5028 19.0631 15.0585 20.2595 16.8535 20.2595C17.751 20.2595 18.5887 20.0202 19.187 19.6015L21.4008 21.3361C20.264 22.4128 18.7083 23.011 16.8535 23.011C14.2208 23.011 11.8873 21.5156 10.6907 19.2426Z"
        fill="#3F260A"
      />
      <path
        d="M10.6904 19.2425C10.2716 18.2855 9.97241 17.2686 9.97241 16.1321C9.97241 15.0554 10.2118 14.0385 10.6904 13.0815H12.9641V14.8161C12.7247 15.7134 12.7247 16.6106 12.9641 17.448L10.6904 19.2425Z"
        fill="#3F260A"
      />
      <path
        d="M12.9643 14.8159L10.6907 13.0812C11.8873 10.7484 14.2208 9.31281 16.8535 9.31281C18.7083 9.31281 20.264 9.97078 21.4008 11.1073L19.4862 13.0214C18.7682 12.3634 17.8707 12.0045 16.8535 12.0045C15.0585 12.0045 13.5028 13.2008 12.9643 14.8159Z"
        fill="#3F260A"
      />
      <path
        d="M16.016 1.53668H46.2318C54.6085 1.53668 61.3697 8.35568 61.3697 16.6701C61.3697 25.0443 54.6085 31.8035 46.2318 31.8035H16.016C7.69917 31.8035 0.878174 25.0443 0.878174 16.6701C0.878174 8.35568 7.69917 1.53668 16.016 1.53668Z"
        stroke="#3F260A"
        strokeWidth="1.2"
      />
    </svg>
  );
};
