import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const WashingMaсhine = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7912 0H4.03955C3.19067 0 2.5 0.690674 2.5 1.53955V15.6006C2.5 15.7949 2.65747 15.9521 2.85156 15.9521C3.04584 15.9521 3.20312 15.7949 3.20312 15.6006V5.08887H20.6276V20.1691C20.6276 20.6303 20.2524 21.0055 19.7912 21.0055H4.03955C3.57831 21.0055 3.20312 20.6303 3.20312 20.1691V17.0067C3.20312 16.8124 3.04584 16.6551 2.85156 16.6551C2.65729 16.6551 2.5 16.8124 2.5 17.0067V20.1691C2.5 20.9412 3.07129 21.5823 3.81342 21.692V23.3672C3.81342 23.7162 4.09741 24 4.44623 24H5.68182C5.94403 24 6.18207 23.8352 6.27454 23.5895L6.46753 23.0748H17.2938L17.4868 23.5898C17.5791 23.8352 17.8173 24 18.0793 24H19.3843C19.7333 24 20.0172 23.7162 20.0172 23.3672V21.692C20.7593 21.5823 21.3307 20.9412 21.3307 20.1691V1.53955C21.3307 0.690674 20.6401 0 19.7912 0ZM18.1281 23.2969L17.9118 22.7201C17.8331 22.5115 17.6302 22.3715 17.4072 22.3715H6.35364C6.13098 22.3715 5.92847 22.5115 5.84918 22.721L5.6333 23.2969H4.51654V21.7086H19.314V23.2969H18.1281ZM3.20312 4.38574V1.53955C3.20312 1.07831 3.57831 0.703125 4.03955 0.703125H19.7912C20.2524 0.703125 20.6276 1.07831 20.6276 1.53955V4.38574H3.20312Z"
          fill="#583711"
        />
        <path
          d="M19.5729 9.54547C19.767 9.54547 19.9245 9.38818 19.9245 9.19391V6.14355C19.9245 5.94946 19.767 5.79199 19.5729 5.79199H4.25781C4.06372 5.79199 3.90625 5.94946 3.90625 6.14355V19.9508C3.90625 20.1451 4.06372 20.3024 4.25781 20.3024H19.5729C19.767 20.3024 19.9245 20.1451 19.9245 19.9508V10.6002C19.9245 10.4061 19.767 10.2486 19.5729 10.2486C19.3787 10.2486 19.2214 10.4061 19.2214 10.6002V19.5992H4.60938V6.49512H19.2214V9.19391C19.2214 9.38818 19.3787 9.54547 19.5729 9.54547Z"
          fill="#583711"
        />
        <rect x="17.9219" y="2" width="1" height="1" rx="0.5" fill="#583711" />
        <rect x="13.9219" y="2" width="1" height="1" rx="0.5" fill="#583711" />
        <rect x="15.9219" y="2" width="1" height="1" rx="0.5" fill="#583711" />
        <path
          d="M11.9219 18C14.6833 18 16.9219 15.7614 16.9219 13C16.9219 10.2386 14.6833 8 11.9219 8C9.16045 8 6.92188 10.2386 6.92188 13C6.92188 15.7614 9.16045 18 11.9219 18Z"
          stroke="#583711"
          strokeWidth="0.7"
        />
        <path
          d="M12.9077 16.4423C12.9523 16.6178 12.8462 16.7976 12.6676 16.8276C12.1592 16.9131 11.638 16.8996 11.1328 16.7868C10.5208 16.6502 9.9494 16.3715 9.46488 15.9733C8.98036 15.5752 8.59625 15.0687 8.34355 14.4947C8.135 14.0209 8.02078 13.5122 8.0061 12.9969C8.00094 12.8159 8.15679 12.677 8.33761 12.6866C8.51844 12.6963 8.6555 12.851 8.66357 13.0319C8.68196 13.4444 8.77662 13.8509 8.94374 14.2304C9.155 14.7103 9.47614 15.1338 9.88123 15.4667C10.2863 15.7996 10.764 16.0326 11.2757 16.1468C11.6805 16.2372 12.0976 16.2513 12.5058 16.1894C12.6849 16.1622 12.8632 16.2667 12.9077 16.4423Z"
          fill="#583711"
        />
      </svg>
    </ContainerForFeatures>
  );
};
