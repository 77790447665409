import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const BabyBed = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 21" width="25" height="21">
	
	<path id="форма 4" fill=" #3f260a" d="m1 2c0.2-0.6 0.4-0.8 1-1 0.4-0.2 0.6-0.2 1 0 0.6 0.2 0.8 0.4 1 1 0.2 0.4 0.2 0.6 0 1-0.2 0.6-0.4 0.8-1 1-0.4 0.2-0.6 0.2-1 0-0.6-0.2-0.8-0.4-1-1-0.2-0.4-0.2-0.6 0-1z"/>
	<path id="форма 4 copy" fill=" #3f260a" d="m20 2c0.2-0.6 0.4-0.8 1-1 0.4-0.2 0.6-0.2 1 0 0.6 0.2 0.8 0.4 1 1 0.2 0.4 0.2 0.6 0 1-0.2 0.6-0.4 0.8-1 1-0.4 0.2-0.6 0.2-1 0-0.6-0.2-0.8-0.4-1-1-0.2-0.4-0.2-0.6 0-1z"/>
	<path id="форма 3" fill=" #3f260a" d="m1 2m0 4h2v9h3v-8h1v8h3v-8l1 0.1v7.9h2v-7.9l1-0.1v8h3v-7.9l1-0.1v8h3v-9h2v14h-2v-2h-18v2h-2zm4 1h14v-1h-14z"/>
</svg>

      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 16.5H22.5" stroke="#583711" strokeLinecap="round"/>
        <path d="M1.5 10.0001C4.16667 9.66672 8.5 10.0002 8 16.5001" stroke="#583711" strokeLinecap="round"/>
        <path d="M1.5 7V22.5" stroke="#583711" strokeLinecap="round"/>
        <path d="M1.5 20H22.5" stroke="#583711" strokeLinecap="round"/>
        <path d="M10.5 10.5991V16.3922C10.5 16.4507 10.5524 16.4956 10.6107 16.491C20.1185 15.7344 22.5 20.1757 22.5 22.5V13C22.5 11 20.5 10.5 19.5 10.5H10.6C10.5448 10.5 10.5 10.5439 10.5 10.5991Z" fill="#583711" stroke="#583711" strokeLinecap="round"/>
        <path d="M9.63304 5.744C9.19571 5.744 8.80105 5.632 8.44904 5.408C8.10238 5.184 7.82771 4.85867 7.62504 4.432C7.42238 4.00533 7.32104 3.48533 7.32104 2.872C7.32104 2.25867 7.42238 1.73867 7.62504 1.312C7.82771 0.885334 8.10238 0.56 8.44904 0.336C8.80105 0.112 9.19571 0 9.63304 0C10.0704 0 10.4624 0.112 10.809 0.336C11.161 0.56 11.4384 0.885334 11.641 1.312C11.8437 1.73867 11.945 2.25867 11.945 2.872C11.945 3.48533 11.8437 4.00533 11.641 4.432C11.4384 4.85867 11.161 5.184 10.809 5.408C10.4624 5.632 10.0704 5.744 9.63304 5.744ZM9.63304 4.928C9.91038 4.928 10.153 4.85333 10.361 4.704C10.569 4.55467 10.7317 4.328 10.849 4.024C10.9664 3.72 11.025 3.336 11.025 2.872C11.025 2.408 10.9664 2.024 10.849 1.72C10.7317 1.416 10.569 1.18933 10.361 1.04C10.153 0.890667 9.91038 0.816 9.63304 0.816C9.36104 0.816 9.11838 0.890667 8.90505 1.04C8.69704 1.18933 8.53438 1.416 8.41704 1.72C8.29971 2.024 8.24104 2.408 8.24104 2.872C8.24104 3.336 8.29971 3.72 8.41704 4.024C8.53438 4.328 8.69704 4.55467 8.90505 4.704C9.11838 4.85333 9.36104 4.928 9.63304 4.928Z" fill="#583711"/>
        <path d="M14.3193 4.648V1.096H15.0873V4.648H14.3193ZM12.8873 3.232V2.512H16.5193V3.232H12.8873Z" fill="#583711"/>
      </svg> */}
     
    </ContainerForFeatures>
  );
};
