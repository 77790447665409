import { ContainerForInputIcon } from "../ContainerForInputIcon";

export const Pets = () => {
  return (
    <ContainerForInputIcon>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.375 9C4.41053 9 5.25 8.16053 5.25 7.125C5.25 6.08947 4.41053 5.25 3.375 5.25C2.33947 5.25 1.5 6.08947 1.5 7.125C1.5 8.16053 2.33947 9 3.375 9Z"
          fill="#3F260A"
        />
        <path
          d="M6.75 6C7.78553 6 8.625 5.16053 8.625 4.125C8.625 3.08947 7.78553 2.25 6.75 2.25C5.71447 2.25 4.875 3.08947 4.875 4.125C4.875 5.16053 5.71447 6 6.75 6Z"
          fill="#3F260A"
        />
        <path
          d="M11.25 6C12.2855 6 13.125 5.16053 13.125 4.125C13.125 3.08947 12.2855 2.25 11.25 2.25C10.2145 2.25 9.375 3.08947 9.375 4.125C9.375 5.16053 10.2145 6 11.25 6Z"
          fill="#3F260A"
        />
        <path
          d="M14.625 9C15.6605 9 16.5 8.16053 16.5 7.125C16.5 6.08947 15.6605 5.25 14.625 5.25C13.5895 5.25 12.75 6.08947 12.75 7.125C12.75 8.16053 13.5895 9 14.625 9Z"
          fill="#3F260A"
        />
        <path
          d="M13.005 11.145C12.3525 10.38 11.805 9.7275 11.145 8.9625C10.8 8.5575 10.3575 8.1525 9.83249 7.9725C9.74998 7.9425 9.66749 7.92 9.58498 7.905C9.39748 7.875 9.19499 7.875 8.99999 7.875C8.80499 7.875 8.60249 7.875 8.40749 7.9125C8.32499 7.9275 8.24249 7.95 8.15999 7.98C7.63499 8.16 7.19999 8.565 6.84749 8.97C6.19499 9.735 5.64749 10.3875 4.98749 11.1525C4.00499 12.135 2.79749 13.2225 3.02249 14.745C3.23999 15.51 3.78749 16.2675 4.76999 16.485C5.31749 16.5975 7.06498 16.155 8.92498 16.155H9.05999C10.92 16.155 12.6675 16.59 13.215 16.485C14.1975 16.2675 14.745 15.5025 14.9625 14.745C15.195 13.215 13.9875 12.1275 13.005 11.145Z"
          fill="#3F260A"
        />
      </svg>
    </ContainerForInputIcon>
  );
};
