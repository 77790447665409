export const PriceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71072 19.091C4.15947 18.1643 4.13768 17.0452 4.6526 16.0978L9.67221 6.85712H12.6544L15.4542 11.9724L16.9575 11.1495L14.6081 6.85712H15.4289V5.14291H14.6463L16.2858 1.71423H6.00002L7.789 5.14291H6.85713V6.85712H7.72057L3.14557 15.2792C2.33955 16.7637 2.37377 18.5161 3.23721 19.9676C4.10065 21.4191 5.62455 22.2858 7.31322 22.2858H9.40619V20.5716H7.31322C6.2351 20.5713 5.26197 20.0182 4.71072 19.091ZM8.82822 3.42869H13.5659L12.746 5.14291H9.72283L8.82822 3.42869Z"
        fill="#583711"
      />
      <path
        d="M21.4287 17.1428V12.8571H11.1432V15.4284H9.42896V19.7142H11.1432V22.2855H21.4287V17.9999H19.7145V17.1428H21.4287ZM19.7145 14.5713V15.4284H18.8574V14.5713H19.7145ZM12.8574 14.5713H17.1432V15.4284H12.8574V14.5713ZM11.1432 17.1428H15.429V17.9999H11.1432V17.1428ZM12.8574 20.5713V19.7142H17.1429V20.5713H12.8574ZM19.7145 20.5713H18.8574V19.7142H19.7145V20.5713ZM17.1432 17.9999V17.1428H18.0003V17.9999H17.1432Z"
        fill="#583711"
      />
    </svg>
  );
};
