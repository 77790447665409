import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const Stove = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 8.5H4.99973C4.72361 8.5 4.50024 8.72374 4.50024 8.9999C4.50024 9.27606 4.72361 9.4998 4.99973 9.4998H19C19.2761 9.4998 19.5002 9.27606 19.5002 8.9999C19.5002 8.72374 19.2761 8.5 19 8.5Z"
          fill="#583711"
        />
        <path
          d="M21.0005 0.4999H19.5004C19.5004 0.223785 19.2763 0 19.0002 0H13.0002C12.7241 0 12.5 0.223739 12.5 0.4999H11.5786C11.5786 0.223785 11.3545 0 11.0784 0H5.07833C4.80222 0 4.57806 0.223739 4.57806 0.4999H3.00047C2.72357 0.4999 2.50024 0.724008 2.50024 1.00012V22.9995C2.50024 23.2764 2.72361 23.5005 3.00047 23.5005H3.49995C3.49995 23.7759 3.72411 24 4.00022 24C4.27634 24 4.50044 23.7759 4.50044 23.5005H19.5004C19.5004 23.7759 19.7239 24 20 24C20.2761 24 20.5002 23.7759 20.5002 23.5005H21.0004C21.2758 23.5005 21.5 23.2764 21.5 22.9995V1.00012C21.5 0.724008 21.2758 0.4999 21.0005 0.4999ZM3.49995 1.49998H20.5002V6.49985H3.49995V1.49998ZM20.5002 22.5H3.49995V20.5005H9.5C9.5 20.7759 9.72411 21 10.0002 21H14C14.2761 21 14.5002 20.7759 14.5002 20.5005H20.5002V22.5ZM20.5002 19.5H3.49995V7.49998H20.5002V19.5Z"
          fill="#583711"
        />
        <path
          d="M6.00047 2.50024C5.17134 2.50024 4.50049 3.17188 4.50049 4.00022C4.50049 4.82857 5.17134 5.5002 6.00047 5.5002C6.82807 5.5002 7.50044 4.82857 7.50044 4.00022C7.50044 3.17188 6.82803 2.50024 6.00047 2.50024ZM6.00042 4.50012C5.7243 4.50012 5.5002 4.27565 5.5002 4.00022C5.5002 3.90901 5.52438 3.8237 5.56754 3.75046C5.65401 3.89952 5.8151 4.00022 6.00042 4.00022C6.185 4.00022 6.34609 3.89952 6.43256 3.75046C6.47503 3.8237 6.4999 3.90901 6.4999 4.00022C6.4999 4.27565 6.2758 4.50012 6.00042 4.50012Z"
          fill="#583711"
        />
        <path
          d="M10.0002 2.50024C9.17188 2.50024 8.50024 3.17188 8.50024 4.00022C8.50024 4.82857 9.17188 5.5002 10.0002 5.5002C10.8286 5.5002 11.5002 4.82857 11.5002 4.00022C11.5002 3.17188 10.8286 2.50024 10.0002 2.50024ZM10.0002 4.50012C9.72406 4.50012 9.49995 4.27565 9.49995 4.00022C9.5 3.90901 9.52487 3.8237 9.5673 3.75046C9.65376 3.89952 9.8149 4.00022 10.0002 4.00022C10.1854 4.00022 10.3458 3.89952 10.433 3.75046C10.4755 3.8237 10.5004 3.90901 10.5004 4.00022C10.5004 4.27565 10.2755 4.50012 10.0002 4.50012Z"
          fill="#583711"
        />
        <path
          d="M14 2.50024C13.1716 2.50024 12.5 3.17188 12.5 4.00022C12.5 4.82857 13.1716 5.5002 14 5.5002C14.8291 5.5002 15.5 4.82857 15.5 4.00022C15.5 3.17188 14.8291 2.50024 14 2.50024ZM14 4.50012C13.7246 4.50012 13.5005 4.27565 13.5005 4.00022C13.5004 3.90901 13.5253 3.8237 13.5678 3.75046C13.6542 3.89952 13.8153 4.00022 14 4.00022C14.1845 4.00022 14.3457 3.89952 14.4321 3.75046C14.4753 3.8237 14.5002 3.90901 14.5002 4.00022C14.5002 4.27565 14.2754 4.50012 14 4.50012Z"
          fill="#583711"
        />
        <path
          d="M18.0005 2.50024C17.1713 2.50024 16.5005 3.17188 16.5005 4.00022C16.5005 4.82857 17.1713 5.5002 18.0005 5.5002C18.8281 5.5002 19.5004 4.82857 19.5004 4.00022C19.5004 3.17188 18.8281 2.50024 18.0005 2.50024ZM18.0004 4.50012C17.7243 4.50012 17.5002 4.27565 17.5002 4.00022C17.5002 3.90901 17.5243 3.8237 17.5676 3.75046C17.654 3.89952 17.8151 4.00022 18.0004 4.00022C18.185 4.00022 18.3461 3.89952 18.4326 3.75046C18.475 3.8237 18.5 3.90901 18.5 4.00022C18.5 4.27565 18.2758 4.50012 18.0004 4.50012Z"
          fill="#583711"
        />
      </svg>
    </ContainerForFeatures>
  );
};
