import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const Parking = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.2592 10.0002C19.2592 15.114 15.1137 19.2595 9.99998 19.2595C4.88623 19.2595 0.740723 15.114 0.740723 10.0002C0.740723 4.88648 4.88623 0.740967 9.99998 0.740967C15.1137 0.740967 19.2592 4.88648 19.2592 10.0002ZM8.2592 6.22212L8.2592 6.22237V9.93533H11.9722C11.9748 9.93533 11.9756 9.93495 11.9758 9.93487L11.9758 9.93486C11.9764 9.93461 11.9775 9.93397 11.9788 9.93271C11.9795 9.93198 11.98 9.93129 11.9804 9.93073C11.9807 9.93031 11.9808 9.92997 11.9809 9.92972L11.981 9.92969C11.981 9.9295 11.9814 9.92867 11.9814 9.92607V6.23163C11.9814 6.22902 11.981 6.22819 11.981 6.228L11.9809 6.22798C11.9807 6.22739 11.9801 6.22625 11.9788 6.22499C11.9775 6.22372 11.9764 6.22309 11.9758 6.22284L11.9758 6.22283C11.9756 6.22274 11.9748 6.22237 11.9722 6.22237H8.27771L8.2592 6.22212ZM6.87031 6.22237C6.87031 5.44995 7.49604 4.82422 8.26846 4.82422C8.30657 4.82422 8.34437 4.82735 8.3815 4.83348H11.9722C12.7446 4.83348 13.3703 5.45921 13.3703 6.23163V9.92607C13.3703 10.6985 12.7446 11.3242 11.9722 11.3242H8.23609V14.8428C8.23609 15.2194 7.93376 15.5327 7.55091 15.5327C7.16805 15.5327 6.86572 15.2194 6.86572 14.8428V10.4169C6.86572 10.3899 6.86728 10.3632 6.87031 10.337V6.22237Z" fill="#583711"/>
      </svg>

      {/* <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8994 19.7419C16.1752 19.7419 19.6413 16.2757 19.6413 12C19.6413 7.72424 16.1752 4.25806 11.8994 4.25806C7.62365 4.25806 4.15747 7.72424 4.15747 12C4.15747 16.2757 7.62365 19.7419 11.8994 19.7419Z"
          stroke="#583711"
          strokeMiterlimit="10"
        />
        <path
          d="M10.4517 8.26074H13.5484C13.7045 8.26074 13.8542 8.32273 13.9645 8.43308C14.0748 8.54342 14.1368 8.69308 14.1368 8.84913V11.9459C14.1368 12.102 14.0748 12.2516 13.9645 12.362C13.8542 12.4723 13.7045 12.5343 13.5484 12.5343H9.86328V8.84913C9.86328 8.69308 9.92527 8.54342 10.0356 8.43308C10.146 8.32273 10.2956 8.26074 10.4517 8.26074Z"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1343 12.3485C10.1343 12.1903 10.0077 12.062 9.85167 12.062C9.69561 12.062 9.56909 12.1903 9.56909 12.3485V16.0491C9.56909 16.2073 9.69561 16.3356 9.85167 16.3356C10.0077 16.3356 10.1343 16.2073 10.1343 16.0491V12.3485Z"
          fill="#583711"
          stroke="#583711"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </svg> */}
    </ContainerForFeatures>
  );
};
