import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const Dishes = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9999 7.59981H22.8254C22.4842 7.97739 21.9379 8.39982 21.1998 8.39982H20.5998C20.3786 8.39982 20.1998 8.57903 20.1998 8.7998C20.1998 9.02058 20.3786 9.19979 20.5998 9.19979H21.1998C23.0258 9.19979 23.9206 7.453 23.9578 7.37861C24.0198 7.25463 24.013 7.1074 23.9402 6.98941C23.867 6.8718 23.7386 6.7998 23.5998 6.7998H19.9998C19.7786 6.7998 19.5999 6.97901 19.5999 7.19979C19.5999 7.42061 19.7787 7.59981 19.9999 7.59981Z"
          fill="#583711"
        />
        <path
          d="M15.5999 11.5999C15.3787 11.5999 15.1999 11.7791 15.1999 11.9999C15.1999 14.867 12.8672 17.1998 10 17.1998C7.13282 17.1998 4.80001 14.8671 4.80001 11.9999C4.80001 9.13273 7.13277 6.79996 9.99995 6.79996C10.2212 6.79996 10.3999 6.62076 10.3999 6.39998C10.3999 6.1792 10.2212 6 9.99995 6C6.69159 6 4 8.69159 4 12C4 15.3083 6.69159 17.9999 9.99995 17.9999C13.3083 17.9999 15.9999 15.3083 15.9999 12C15.9999 11.7791 15.8211 11.5999 15.5999 11.5999Z"
          fill="#583711"
        />
        <path
          d="M11.4852 7.01508C13.174 7.51706 14.4816 8.82505 14.9844 10.5131C15.036 10.6866 15.1948 10.7995 15.3676 10.7995C15.4052 10.7995 15.4436 10.7939 15.4816 10.7827C15.6932 10.7195 15.814 10.4967 15.7508 10.2851C15.1712 8.33671 13.6616 6.82791 11.7132 6.24835C11.5016 6.18474 11.2789 6.30633 11.216 6.51755C11.1528 6.7291 11.2736 6.95189 11.4852 7.01508Z"
          fill="#583711"
        />
        <path
          d="M23.5998 18.7998H18.3998C18.1786 18.7998 17.9998 18.979 17.9998 19.1998C17.9998 19.4206 18.1786 19.5998 18.3998 19.5998H22.8254C22.4842 19.9773 21.9378 20.3998 21.1997 20.3998H17.1998C17.121 20.3998 17.0434 20.4234 16.9778 20.467L15.8786 21.1998H15.1998C14.9786 21.1998 14.7998 21.379 14.7998 21.5998C14.7998 21.8205 14.9786 21.9997 15.1998 21.9997H15.9998C16.0786 21.9997 16.1562 21.9762 16.2218 21.9325L17.321 21.1997H21.1998C23.0258 21.1997 23.9206 19.453 23.9577 19.3786C24.0198 19.2546 24.013 19.1073 23.9402 18.9894C23.867 18.8718 23.7386 18.7998 23.5998 18.7998Z"
          fill="#583711"
        />
        <path
          d="M23.5998 10.7998H20.9998C20.7786 10.7998 20.5999 10.979 20.5999 11.1998C20.5999 11.4206 20.7786 11.5998 20.9998 11.5998H22.8254C22.4842 11.9773 21.9378 12.3998 21.1998 12.3998H20.9998C20.7786 12.3998 20.5999 12.579 20.5999 12.7998C20.5999 13.0205 20.7786 13.1997 20.9998 13.1997H21.1998C23.0258 13.1997 23.9206 11.453 23.9578 11.3786C24.0198 11.2546 24.013 11.1073 23.9402 10.9894C23.867 10.8718 23.7386 10.7998 23.5998 10.7998Z"
          fill="#583711"
        />
        <path
          d="M23.5999 14.7998H20.5999C20.3787 14.7998 20.1999 14.979 20.1999 15.1998C20.1999 15.4206 20.3787 15.5998 20.5999 15.5998H22.8254C22.4842 15.9773 21.9379 16.3998 21.1998 16.3998H19.9998C19.7786 16.3998 19.5999 16.579 19.5999 16.7998C19.5999 17.0205 19.7786 17.1997 19.9998 17.1997H21.1998C23.0258 17.1997 23.9206 15.453 23.9578 15.3786C24.0198 15.2546 24.013 15.1073 23.9402 14.9894C23.867 14.8718 23.7386 14.7998 23.5999 14.7998Z"
          fill="#583711"
        />
        <path
          d="M19.9999 11.9999C19.9999 6.48595 15.5139 2 9.99995 2C4.486 2 0 6.48595 0 11.9999C0 17.5139 4.48595 21.9998 9.99991 21.9998C15.5139 21.9998 19.9999 17.5139 19.9999 11.9999ZM9.99991 21.1998C4.92676 21.1998 0.799963 17.0726 0.799963 11.9999C0.799963 6.92709 4.92676 2.79996 9.99991 2.79996C15.0731 2.79996 19.1999 6.92713 19.1999 11.9999C19.1999 17.0727 15.0731 21.1998 9.99991 21.1998Z"
          fill="#583711"
        />
      </svg>
    </ContainerForFeatures>
  );
};
