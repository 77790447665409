import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const Microwave = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.3485 4.1377H1.65152C0.740701 4.1377 0 4.88164 0 5.79593V18.2037C0 19.118 0.740701 19.862 1.65152 19.862H22.3485C23.2593 19.862 24 19.118 24 18.2037V5.79593C24 4.88164 23.2593 4.1377 22.3485 4.1377ZM23.1724 18.2037C23.1724 18.6618 22.8026 19.0344 22.3485 19.0344H1.65152C1.19728 19.0344 0.827605 18.6618 0.827605 18.2037V5.79593C0.827605 5.33789 1.1974 4.9653 1.65152 4.9653H22.3485C22.8027 4.9653 23.1724 5.33789 23.1724 5.79593V18.2037Z"
          fill="#583711"
        />
        <path
          d="M16.9656 5.79297H2.06908C1.84039 5.79297 1.65527 5.97831 1.65527 6.20677V17.793C1.65527 18.0215 1.84039 18.2068 2.06908 18.2068H16.9656C17.1943 18.2068 17.3794 18.0215 17.3794 17.793V6.20688C17.3793 5.9782 17.1943 5.79297 16.9656 5.79297ZM2.48277 17.3791V6.62069H16.5517V17.3792H2.48277V17.3791Z"
          fill="#583711"
        />
        <path
          d="M19.862 13.655C18.7213 13.655 17.793 14.5832 17.793 15.724C17.793 16.8649 18.7211 17.7931 19.862 17.7931C21.0027 17.7931 21.931 16.8649 21.931 15.724C21.931 14.5832 21.0027 13.655 19.862 13.655ZM18.6206 15.724C18.6206 15.0394 19.1774 14.4826 19.862 14.4826C20.053 14.4826 20.232 14.5296 20.3938 14.6069L18.745 16.2559C18.6676 16.0941 18.6206 15.915 18.6206 15.724ZM19.862 16.9656C19.6709 16.9656 19.492 16.9186 19.3301 16.8413L20.979 15.1923C21.0564 15.3541 21.1034 15.5332 21.1034 15.7242C21.1034 16.4085 20.5465 16.9656 19.862 16.9656Z"
          fill="#583711"
        />
        <path
          d="M21.2748 10.3446L20.6897 9.75952L18.6417 11.8076L19.2268 12.3927L21.2748 10.3446Z"
          fill="#583711"
        />
        <path
          d="M21.2746 7.44816L20.6895 6.86304L18.6414 8.91108L19.2265 9.4962L21.2746 7.44816Z"
          fill="#583711"
        />
      </svg>
    </ContainerForFeatures>
  );
};
