import { ContainerForInputIcon } from "../ContainerForInputIcon";

export const Email = () => {
  return (
    <ContainerForInputIcon>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 9.01127C1.5 5.06034 4.6575 1.5 9.015 1.5C13.275 1.5 16.5 4.99274 16.5 8.98873C16.5 13.6232 12.72 16.5 9 16.5C7.77 16.5 6.405 16.1695 5.31 15.5235C4.9275 15.2907 4.605 15.1179 4.1925 15.2531L2.6775 15.7038C2.295 15.824 1.95 15.5235 2.0625 15.1179L2.565 13.4354C2.6475 13.2026 2.6325 12.9547 2.5125 12.7594C1.8675 11.5726 1.5 10.2732 1.5 9.01127ZM8.025 9.01127C8.025 9.54457 8.4525 9.97271 8.985 9.98022C9.5175 9.98022 9.945 9.54457 9.945 9.01878C9.945 8.48548 9.5175 8.05734 8.985 8.05734C8.46 8.04982 8.025 8.48548 8.025 9.01127ZM11.4825 9.01878C11.4825 9.54457 11.91 9.98022 12.4425 9.98022C12.975 9.98022 13.4025 9.54457 13.4025 9.01878C13.4025 8.48548 12.975 8.05734 12.4425 8.05734C11.91 8.05734 11.4825 8.48548 11.4825 9.01878ZM5.5275 9.98022C5.0025 9.98022 4.5675 9.54457 4.5675 9.01878C4.5675 8.48548 4.995 8.05734 5.5275 8.05734C6.06 8.05734 6.4875 8.48548 6.4875 9.01878C6.4875 9.54457 6.06 9.97271 5.5275 9.98022Z"
          fill="#3F260A"
        />
      </svg>
    </ContainerForInputIcon>
  );
};
