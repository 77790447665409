export const SamsungPay = () => {
  return (
    <svg viewBox="0 0 70 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8922 19.0204H47.9266L42.9006 31.3424H40.9261L42.7211 27.0357L39.2507 19.0204H41.3449L43.6784 24.7029L45.8922 19.0204Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.806 18.8405C34.9428 18.8405 35.9002 19.319 36.4985 20.0368V19.02H38.473V27.454H36.4985V26.3773C35.9002 27.1549 34.883 27.6334 33.806 27.6334C31.7717 27.6334 29.6177 26.0184 29.6177 23.2669C29.6177 20.4555 31.7118 18.8405 33.806 18.8405ZM34.1052 20.635C32.6692 20.635 31.5323 21.7715 31.5323 23.2071C31.5323 24.7025 32.6692 25.839 34.1052 25.839C35.5412 25.839 36.6181 24.7025 36.6181 23.2071C36.6181 21.7715 35.5412 20.635 34.1052 20.635Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3097 16.927C27.5236 16.927 29.1391 18.6018 29.1391 20.6954C29.1391 22.7889 27.5236 24.4638 25.3097 24.4638H23.6943V27.4546H21.7197V16.927H25.3097ZM25.1901 18.7215H23.6943V22.6095H25.1901C26.3269 22.6095 27.1047 21.7721 27.1047 20.6954C27.1047 19.6187 26.3269 18.7215 25.1901 18.7215Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.2273 5.02317V7.71489C69.2273 7.89433 69.1675 8.01397 69.1675 8.31304C68.988 10.1075 67.4323 10.7655 65.6971 10.7655C63.962 10.7655 62.4063 10.1075 62.2268 8.31304C62.2268 8.01397 62.2268 7.89433 62.2268 7.71489V3.52778C62.2268 3.34833 62.2268 3.04925 62.2268 2.92962C62.4662 1.07533 63.962 0.477173 65.6971 0.477173C67.4323 0.477173 68.988 1.07533 69.1675 2.92962C69.1675 3.2287 69.1675 3.52778 69.1675 3.52778V3.88667H66.7143V3.34833C66.7143 3.34833 66.7144 3.10907 66.6545 2.92962C66.6545 2.75017 66.4151 2.21183 65.6971 2.21183C64.9791 2.21183 64.7398 2.69036 64.7398 2.92962C64.68 3.04925 64.68 3.2287 64.68 3.40815V7.89433C64.68 8.01396 64.68 8.19341 64.68 8.31304C64.7398 8.55231 64.9791 9.03083 65.6971 9.03083C66.4151 9.03083 66.6545 8.55231 66.7143 8.31304C66.7143 8.19341 66.7143 8.05384 66.7143 7.89433V6.45875H65.6971V5.02317H69.2273Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.2009 0.716553H59.654V10.4665H56.1238L53.7305 2.51103H53.6707L53.7904 10.4665H51.3971V0.716553H55.0469L57.2607 8.43279H57.3205L57.2009 0.716553Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.6448 7.77482C48.6448 7.95426 48.585 8.31316 48.585 8.43279C48.4055 10.2871 46.9696 10.8852 45.1746 10.8852C43.3796 10.8852 41.9435 10.2871 41.764 8.43279C41.7042 8.31316 41.7042 7.95426 41.7042 7.77482V0.716553H44.2172V8.01408C44.2172 8.13371 44.2172 8.31316 44.2172 8.37297C44.2771 8.61224 44.5164 9.09076 45.1746 9.09076C45.8327 9.09076 46.072 8.61224 46.1319 8.37297C46.1319 8.31316 46.1319 8.13371 46.1319 8.01408V0.716553H48.6448V7.77482Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3707 7.11673C39.4305 7.29617 39.4903 7.83452 39.3707 8.67194C39.1313 10.287 37.6954 10.8851 35.9004 10.8851C34.1054 10.8851 32.3702 10.2271 32.3702 8.07378V7.35599H35.0029V8.25323C35.0029 8.79157 35.3619 9.15046 35.9602 9.15046C36.6184 9.15046 36.8577 8.85138 36.9175 8.49249C36.9773 8.31304 37.0372 8.01396 36.9175 7.7747C36.4388 6.51857 32.9685 5.92041 32.5497 3.94649C32.43 3.46796 32.43 3.04925 32.4898 2.63054C32.789 1.07533 34.1652 0.477173 35.9004 0.477173C37.2765 0.477173 39.1912 0.836068 39.1912 2.92962V3.58759H36.738V2.98944C36.738 2.51091 36.4389 2.15202 35.8405 2.15202C35.2422 2.15202 35.0627 2.45109 34.943 2.80999C34.943 2.86981 34.943 3.10907 35.0029 3.28852C35.2422 4.42502 38.9518 5.02317 39.3707 7.11673Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7974 0.716553L29.9769 10.5862H27.5238L27.464 1.73342H27.4041L25.7886 10.5862H23.2756L21.6003 1.73342H21.5405L21.4806 10.5862H19.0274L19.2668 0.716553H23.2756L24.4723 8.25334H24.5321L25.7288 0.716553H29.7974Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7273 10.5862H9.03484L10.8897 0.716553H15.2575L17.0525 10.5862H14.4198L13.1035 1.6736H13.0436L11.7273 10.5862Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.359131 8.1336V7.35599H2.99181V8.31304C2.99181 8.85139 3.35078 9.21028 4.00895 9.21028C4.66712 9.21028 4.8466 8.85139 4.96627 8.55231C4.96627 8.37286 5.02612 8.01396 4.90645 7.7747C4.42778 6.51857 0.897602 5.92041 0.478768 3.94649C0.418935 3.40815 0.418935 3.04925 0.478768 2.63054C0.718102 1.01552 2.15415 0.417358 3.88931 0.417358C5.32531 0.417358 7.23997 0.776253 7.23997 2.92962V3.5876H4.78681V2.98944C4.78681 2.4511 4.42781 2.0922 3.88931 2.0922C3.23115 2.0922 3.05166 2.4511 2.93199 2.75017C2.93199 2.86981 2.93198 3.04925 2.99181 3.28852C3.23115 4.42502 7.00066 5.02318 7.41949 7.11673C7.41949 7.29618 7.53916 7.83452 7.41949 8.73175C7.18016 10.3468 5.6843 10.9449 3.94913 10.9449C2.0943 10.9449 0.359131 10.287 0.359131 8.1336Z"
        fill="#3F260A"
      />
    </svg>
  );
};
