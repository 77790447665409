export const BePaid = () => {
  return (
    <svg viewBox="0 0 74 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.2837C0.0598333 16.4463 0.119674 15.1902 0.119674 13.934V0.116577H3.11134V7.11502H3.1712C3.8892 5.97852 5.14569 5.20092 6.94069 5.20092C9.81269 5.20092 11.847 7.59355 11.847 11.1825C11.847 15.4294 9.15453 17.523 6.46203 17.523C4.9662 17.523 3.59002 16.9248 2.75236 15.4892H2.6925L2.57286 17.2837H0ZM3.05152 12.3788C3.05152 12.658 3.07145 12.8972 3.11134 13.0966C3.47034 14.2929 4.48751 15.1902 5.80384 15.1902C7.65868 15.1902 8.79551 13.6948 8.79551 11.3021C8.79551 9.20858 7.77834 7.53374 5.80384 7.53374C4.60717 7.53374 3.5302 8.43097 3.1712 9.74692C3.11136 9.92637 3.05152 10.2254 3.05152 10.4647V12.3788Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2321 12.1996C17.292 14.3529 18.9673 15.2502 20.882 15.2502C22.2581 15.2502 23.2155 15.0707 24.113 14.7118L24.5318 16.8054C23.5146 17.1643 22.1384 17.5232 20.4631 17.5232C16.6338 17.5232 14.3601 15.1903 14.3601 11.5416C14.3601 8.31153 16.3346 5.20111 20.1639 5.20111C23.9334 5.20111 25.19 8.37135 25.19 10.9434C25.19 11.4818 25.1899 11.9603 25.1301 12.1996H17.2321ZM22.3778 10.106C22.4376 9.02932 21.959 7.23485 19.9246 7.23485C18.1296 7.23485 17.3518 8.90969 17.2321 10.106H22.3778Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6003 1.1938C29.6174 1.01435 31.1133 0.8349 33.1476 0.8349C35.3016 0.8349 36.9171 1.31343 37.9343 2.15085C38.8916 2.98827 39.5498 4.2444 39.5498 5.79961C39.5498 7.35482 39.0711 8.61095 38.1138 9.50819C36.9171 10.6447 35.0623 11.2429 32.9681 11.2429C32.4296 11.2429 31.8911 11.183 31.5321 11.1232V17.2842H28.6003V1.1938ZM31.5321 8.7904C31.8911 8.85022 32.3698 8.91003 32.9681 8.91003C35.1819 8.91003 36.5581 7.77353 36.5581 5.85943C36.5581 4.06495 35.3016 3.1079 33.2673 3.1079C32.4296 3.1079 31.8313 3.16772 31.5321 3.22753V8.7904Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9438 17.2839L48.7642 15.968H48.7044C47.9864 16.8652 46.7299 17.5232 45.1742 17.5232C42.8407 17.5232 41.5244 15.7885 41.5244 13.994C41.5244 11.0631 44.1571 9.56766 48.4651 9.56766V9.38822C48.4651 8.61061 48.1659 7.35448 46.0717 7.35448C44.9349 7.35448 43.7382 7.71337 42.9006 8.1919L42.3621 6.27779C43.1998 5.73945 44.7554 5.20111 46.6102 5.20111C50.3797 5.20111 51.4567 7.59374 51.4567 10.1658V14.4127C51.4567 15.4894 51.5166 16.5661 51.6363 17.2839H48.9438ZM48.5249 11.4818C46.4308 11.4818 44.4563 11.9005 44.4563 13.695C44.4563 14.8913 45.1743 15.4296 46.1316 15.4296C47.3283 15.4296 48.1659 14.652 48.4651 13.7548C48.5249 13.5753 48.5249 13.3361 48.5249 13.1566V11.4818Z"
        fill="#3F260A"
      />
      <path
        d="M55.4655 5.50036H58.4572V17.2841H55.4655V5.50036ZM58.6367 2.21049C58.6367 3.10772 57.9785 3.7657 56.9614 3.7657C55.9442 3.7657 55.2861 3.10772 55.2861 2.21049C55.2861 1.25343 55.9442 0.595459 56.9614 0.595459C57.9785 0.595459 58.5769 1.25343 58.6367 2.21049Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.3559 0.116699V13.9341C73.3559 15.1903 73.4157 16.4464 73.4756 17.2838H70.7831L70.6634 15.4295H70.6035C69.9454 16.6857 68.5094 17.5231 66.7742 17.5231C63.962 17.5231 61.7482 15.1305 61.7482 11.4817C61.7482 7.59367 64.2014 5.20104 67.0135 5.20104C68.629 5.20104 69.8257 5.91883 70.3642 6.75625V0.116699H73.3559ZM70.424 10.405C70.424 10.1658 70.3642 9.86667 70.3044 9.62741C70.065 8.49091 69.1077 7.53386 67.7914 7.53386C65.8767 7.53386 64.7997 9.2087 64.7997 11.4219C64.7997 13.5753 65.8767 15.1305 67.7315 15.1305C68.9282 15.1305 70.0052 14.293 70.3044 13.0369C70.3841 12.7578 70.424 12.4587 70.424 12.1397V10.405Z"
        fill="#3F260A"
      />
    </svg>
  );
};
