import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const PersonalPier = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.81836 7.63916H19.2496" stroke="#583711" strokeWidth="1.35824" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M3.18848 10.2283H20.8795" stroke="#583711" strokeWidth="1.35824" strokeMiterlimit="10" strokeLinecap="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.321 17.8012V10.2283H2.6792V17.8012C2.6792 19.1797 3.74881 20.2842 5.0646 20.2842H18.9356C20.2514 20.2842 21.321 19.1797 21.321 17.8012ZM6.04094 14.8322C6.78797 14.143 8.26505 13.7807 9.10546 14.4788L9.13205 14.4961C9.24646 14.5708 9.28514 14.5961 9.41955 14.629C9.89815 14.7239 10.3904 14.5852 10.9022 14.4411L10.9023 14.441C10.9624 14.4241 11.0228 14.4071 11.0834 14.3904C11.3126 14.3197 11.5588 14.4611 11.6267 14.7085C11.6776 14.8941 11.6097 15.0885 11.4739 15.1945C11.0919 15.495 10.6674 15.7689 10.1496 15.9368C9.60631 16.1224 8.8423 16.1224 8.34994 15.7689C8.24024 15.698 8.17437 15.6517 8.12453 15.6167C8.06252 15.5731 8.02534 15.5469 7.95945 15.5126C7.49521 15.3079 6.98722 15.3764 6.43546 15.4509L6.3041 15.4684C5.96454 15.5303 5.76081 15.0355 6.04094 14.8234V14.8322ZM18.4856 16.9088C18.4156 16.9088 18.3465 16.9081 18.2784 16.9074C17.8597 16.9032 17.4779 16.8994 17.1274 17.059C16.8812 17.2269 16.3634 17.4655 16.0069 17.4301C15.4296 17.4478 13.6299 16.9088 14.0883 16.1135C14.1902 15.9633 14.3685 15.8926 14.5383 15.9279C14.6115 15.9378 14.688 15.9493 14.7666 15.9612L14.7666 15.9612C15.2526 16.0345 15.8162 16.1196 16.1597 15.8837C16.2615 15.8131 16.6011 15.6628 16.8558 15.6452C17.3821 15.5391 19.1902 16.034 18.7912 16.7586C18.7148 16.8558 18.6045 16.9088 18.4941 16.9088H18.4856Z" fill="#583711"/>
      <path d="M18.9356 3.70703H5.0646C3.74718 3.70703 2.6792 4.81874 2.6792 6.19009V17.8101C2.6792 19.1815 3.74718 20.2932 5.0646 20.2932H18.9356C20.253 20.2932 21.321 19.1815 21.321 17.8101V6.19009C21.321 4.81874 20.253 3.70703 18.9356 3.70703Z" stroke="#583711" strokeWidth="1.35824" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.19524 8.00155L6.88964 11.4036C6.85568 11.8631 6.17657 11.8543 6.1511 11.4036L5.8455 8.00155C5.78607 6.98535 7.26315 6.97651 7.20373 8.00155H7.19524Z" fill="#583711"/>
      <path d="M9.95403 8.00155L9.64843 11.4036C9.61447 11.8631 8.93536 11.8543 8.90989 11.4036L8.60429 8.00155C8.54486 6.98535 10.0219 6.97651 9.96252 8.00155H9.95403Z" fill="#583711"/>
      <path d="M12.7131 8.00155L12.4075 11.4036C12.3735 11.8631 11.6944 11.8543 11.6689 11.4036L11.3633 8.00155C11.3039 6.98535 12.781 6.97651 12.7216 8.00155H12.7131Z" fill="#583711"/>
      <path d="M15.4636 8.00155L15.1579 11.4036C15.124 11.8631 14.4449 11.8543 14.4194 11.4036L14.1138 8.00155C14.0544 6.98535 15.5315 6.97651 15.472 8.00155H15.4636Z" fill="#583711"/>
      <path d="M18.2223 8.00155L17.9167 11.4036C17.8828 11.8631 17.2037 11.8543 17.1782 11.4036L16.8726 8.00155C16.8132 6.98535 18.2903 6.97651 18.2308 8.00155H18.2223Z" fill="#583711"/>
      </svg>

      {/* <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1605_3273)">
          <path
            d="M4.81836 7.63916H19.2496"
            stroke="#583711"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M3.18848 10.2283H20.8795"
            stroke="#583711"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M6.0407 14.8322C6.78773 14.143 8.26481 13.7807 9.10522 14.4788C9.24104 14.5671 9.275 14.5936 9.41931 14.629C9.95411 14.735 10.5059 14.5495 11.0831 14.3904C11.3123 14.3197 11.5585 14.4611 11.6264 14.7085C11.6774 14.8941 11.6095 15.0885 11.4736 15.1945C11.0916 15.495 10.6672 15.7689 10.1494 15.9368C9.60607 16.1224 8.84206 16.1224 8.3497 15.7689C8.10352 15.6098 8.07805 15.5745 7.95921 15.5126C7.45836 15.2917 6.90658 15.3889 6.30386 15.4684C5.9643 15.5303 5.76056 15.0355 6.0407 14.8234V14.8322Z"
            fill="#583711"
          />
          <path
            d="M7.19524 8.00155L6.88964 11.4036C6.85568 11.8631 6.17657 11.8543 6.1511 11.4036L5.8455 8.00155C5.78607 6.98535 7.26315 6.97651 7.20373 8.00155H7.19524Z"
            fill="#583711"
          />
          <path
            d="M9.95403 8.00155L9.64843 11.4036C9.61447 11.8631 8.93536 11.8543 8.90989 11.4036L8.60429 8.00155C8.54486 6.98535 10.0219 6.97651 9.96252 8.00155H9.95403Z"
            fill="#583711"
          />
          <path
            d="M12.7131 8.00155L12.4075 11.4036C12.3735 11.8631 11.6944 11.8543 11.6689 11.4036L11.3633 8.00155C11.3039 6.98535 12.781 6.97651 12.7216 8.00155H12.7131Z"
            fill="#583711"
          />
          <path
            d="M15.4633 8.00155L15.1577 11.4036C15.1237 11.8631 14.4446 11.8543 14.4192 11.4036L14.1136 8.00155C14.0541 6.98535 15.5312 6.97651 15.4718 8.00155H15.4633Z"
            fill="#583711"
          />
          <path
            d="M18.2223 8.00155L17.9167 11.4036C17.8828 11.8631 17.2037 11.8543 17.1782 11.4036L16.8726 8.00155C16.8132 6.98535 18.2903 6.97651 18.2308 8.00155H18.2223Z"
            fill="#583711"
          />
          <path
            d="M18.4771 16.9085C17.9762 16.9085 17.5263 16.8732 17.1189 17.0588C16.8727 17.2267 16.3548 17.4652 15.9983 17.4299C15.4211 17.4476 13.6214 16.9085 14.0798 16.1133C14.1817 15.963 14.3599 15.8923 14.5297 15.9277C15.056 15.9984 15.7521 16.1574 16.1511 15.8835C16.253 15.8128 16.5925 15.6626 16.8472 15.6449C17.3735 15.5389 19.1817 16.0337 18.7827 16.7583C18.7063 16.8555 18.5959 16.9085 18.4856 16.9085H18.4771Z"
            fill="#583711"
          />
          <path
            d="M18.9356 3.70703H5.0646C3.74718 3.70703 2.6792 4.81874 2.6792 6.19009V17.8101C2.6792 19.1815 3.74718 20.2932 5.0646 20.2932H18.9356C20.253 20.2932 21.321 19.1815 21.321 17.8101V6.19009C21.321 4.81874 20.253 3.70703 18.9356 3.70703Z"
            stroke="#583711"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1605_3273">
            <rect
              width="20"
              height="18"
              fill="white"
              transform="translate(2 3)"
            />
          </clipPath>
        </defs>
      </svg> */}
    </ContainerForFeatures>
  );
};
