import { ContainerForInputIcon } from "../ContainerForInputIcon";

export const Phone = () => {
  return (
    <ContainerForInputIcon>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.625 10.7848C13.1212 10.6764 12.7227 10.9103 12.3698 11.1146C12.0084 11.3251 11.3212 11.8825 10.9273 11.7398C8.91069 10.9095 7.01398 9.14439 6.19292 7.11965C6.04821 6.71735 6.60306 6.0258 6.812 5.66015C7.01475 5.30619 7.24381 4.90389 7.13934 4.39634C7.04493 3.94024 5.82379 2.38641 5.39198 1.9615C5.1072 1.68083 4.81546 1.52646 4.51598 1.50151C3.39003 1.45317 2.13252 2.95555 1.91197 3.31497C1.35944 4.08136 1.36253 5.10114 1.92125 6.33766C3.26776 9.65896 8.36048 14.6713 11.6942 16.0684C12.3094 16.3561 12.872 16.5004 13.3774 16.5004C13.8719 16.5004 14.3122 16.3624 14.6906 16.0887C14.9761 15.9242 16.5401 14.6043 16.4991 13.448C16.4743 13.1533 16.3203 12.8586 16.0433 12.5733C15.6215 12.1375 14.0777 10.8799 13.625 10.7848Z"
          fill="#3F260A"
        />
      </svg>
    </ContainerForInputIcon>
  );
};
