import { ContainerForFeatures } from "../ContainerForFeatures";

interface IProps {
  littleIcon?: boolean;
}

export const Mangal = (props: IProps) => {
  return (
    <ContainerForFeatures littleIcon={props.littleIcon}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1034_24876)">
        <path d="M24.5385 4.42391C24.3698 4.08598 24.1191 3.75991 23.5956 3.83816C23.1704 3.90169 22.7902 4.02003 22.1217 3.61004C20.1498 1.93833 16.6266 0.824585 12.6069 0.824585C8.58566 0.824585 5.06119 1.93916 3.0901 3.61167C2.42323 4.01964 2.04348 3.90169 1.61916 3.83816C1.09566 3.75995 0.844984 4.08598 0.676162 4.42391C0.441841 4.89295 -0.512189 6.556 0.361567 7.25527C1.03951 7.79803 1.37051 8.13799 1.53312 8.33873C2.23116 13.1117 6.19138 18.0223 12.6069 18.0223C19.0234 18.0223 22.9836 13.1117 23.6816 8.33873C23.8442 8.13799 24.1752 7.79803 24.8531 7.25527C25.7269 6.556 24.7728 4.89295 24.5385 4.42391ZM3.52141 6.69567C3.52141 4.89945 5.60932 3.35026 8.64417 2.60598L3.64344 7.40765C3.56977 7.17495 3.52141 6.93861 3.52141 6.69567ZM4.41279 8.66145C4.37719 8.62461 4.34606 8.5857 4.31203 8.54798L10.8898 2.23362C10.9734 2.22544 11.0532 2.21229 11.138 2.20535C11.6402 2.15989 12.1543 2.13862 12.677 2.13862C13.0121 2.13862 13.3402 2.15211 13.6658 2.17181L5.80631 9.70934C5.24927 9.39386 4.77654 9.04489 4.41279 8.66145ZM9.23081 10.921C8.46397 10.7678 7.75042 10.5605 7.1036 10.3155C7.08843 10.3098 7.07494 10.3032 7.06021 10.2971L15.3535 2.33482C15.3719 2.33728 15.3912 2.33891 15.4093 2.34176C16.201 2.46631 16.9433 2.64493 17.6278 2.86443C17.6359 2.86689 17.6437 2.87019 17.6519 2.87305L9.25908 10.9259C9.25007 10.9242 9.24022 10.923 9.23081 10.921ZM13.6011 11.2319C13.2988 11.2487 12.9879 11.2569 12.677 11.2569C12.0565 11.2569 11.4489 11.2249 10.8624 11.1643L18.964 3.38961C19.6283 3.69805 20.2006 4.05973 20.6544 4.46528C20.6897 4.49562 20.7212 4.52758 20.7553 4.55871L13.8178 11.2151C13.7449 11.2192 13.6744 11.2286 13.6011 11.2319ZM15.956 10.9496L21.5495 5.58272C21.731 5.93952 21.8317 6.31267 21.8317 6.69567C21.8317 8.64101 19.394 10.2947 15.956 10.9496Z" fill="#3F260A"/>
        <path d="M18.6036 17.0695C17.958 17.464 17.2579 17.7953 16.5067 18.0526L17.364 20.1974H7.85043L8.73029 17.9952C7.9602 17.8019 7.2401 17.5291 6.56871 17.1944L4.39486 23.6333C4.19698 24.1724 4.44194 24.7598 4.94293 24.9441C5.44433 25.1289 6.01122 24.8405 6.20989 24.3018L7.18686 21.8576H18.0276L19.0046 24.3018C19.2033 24.8405 19.7702 25.1288 20.2715 24.9441C20.7725 24.7598 21.0175 24.1724 20.8197 23.6333L18.6036 17.0695Z" fill="#3F260A"/>
        </g>
        <defs>
        <clipPath id="clip0_1034_24876">
        <rect width="25" height="25" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      {/* <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.12772 16.9858L7.12256 20.0052"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1123 16.9858L17.1175 20.0052"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7045 11.9536C18.7045 14.0517 17.1561 17.0323 12.1239 17.0323C7.4787 17.0323 5.54321 14.0517 5.54321 11.9536"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1239 13.2465C15.7582 13.2465 18.7045 12.5914 18.7045 11.7833C18.7045 10.9752 15.7582 10.3201 12.1239 10.3201C8.48947 10.3201 5.54321 10.9752 5.54321 11.7833C5.54321 12.5914 8.48947 13.2465 12.1239 13.2465Z"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1238 18.8826V17.311"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9549 10.7227L9.5459 12.8517"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7251 10.8079L13.2231 13.084"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9756 12.4877C19.5795 12.96 20.2298 13.0684 20.524 12.7974C20.6249 12.7172 20.6958 12.6054 20.7253 12.48"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.36516 12.4877C4.76129 12.96 4.11096 13.0684 3.81677 12.7974C3.71589 12.7172 3.64497 12.6054 3.61548 12.48"
          stroke="#583711"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg> */}
    </ContainerForFeatures>
  );
};
