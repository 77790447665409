export const Visa = () => {
  return (
    <svg viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.1643 0.92627L17.7705 23.3572H11.6675L7.06033 5.47227C7.0005 5.05356 6.88083 4.63485 6.6415 4.27595C6.40217 3.91706 6.04317 3.6778 5.68417 3.49835C3.88917 2.60111 1.9745 2.00295 0 1.58424L0.119667 0.92627H9.99217C10.6503 0.92627 11.2487 1.16553 11.7273 1.58424C12.206 2.00295 12.565 2.60111 12.6248 3.19927L15.078 16.1793L21.1212 0.92627H27.1643Z"
        fill="#3F260A"
      />
      <path
        d="M35.4815 0.92627L30.6948 23.2974H24.891L29.6776 0.92627H35.4815Z"
        fill="#3F260A"
      />
      <path
        d="M43.08 7.14688C43.0201 9.83859 51.1575 10.1377 51.1575 16.0594C51.1575 20.6653 47.2085 23.7159 41.1055 23.7159C38.8916 23.7159 36.6778 23.2971 34.6435 22.5195L35.7205 17.6146C37.5155 18.5717 39.5498 19.0502 41.644 18.9904C43.3193 18.9904 45.1143 18.3324 45.1143 16.837C45.1741 15.8202 44.2766 15.1024 42.1226 14.0257C39.9088 12.949 37.0368 11.1545 37.0368 7.92449C37.0966 3.55793 41.1055 0.507324 46.8495 0.507324C48.7043 0.507324 50.4993 0.866219 52.2345 1.52419L51.2173 6.24964C49.4223 5.41222 47.4478 5.05333 45.5331 5.23277C43.798 5.47204 43.08 6.30946 43.08 7.14688Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.9533 0.92627L71.6801 23.3572H66.355L65.637 20.0075H58.2176L57.021 23.3572H50.918L59.534 2.5413C59.7733 2.06277 60.0725 1.64406 60.5511 1.34498C60.97 1.10572 61.5085 0.92627 62.047 0.92627H66.9533ZM64.6198 15.4017L62.8846 6.96766L59.8331 15.4017H64.6198Z"
        fill="#3F260A"
      />
    </svg>
  );
};
