export const VisaSecure = () => {
  return (
    <svg viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.365479 15.3602H41.4112V35.877H0.365479V15.3602Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9908 0.586003L18.0163 9.79764H15.623L17.5975 0.586003H19.9908ZM32.3763 8.42187H29.3248L28.8461 9.79764H26.3331L29.9231 1.24398C30.1026 0.885082 30.4616 0.586003 30.9403 0.586003H32.9746L34.8893 9.79764H32.6755L32.3763 8.42187ZM32.0173 6.56758L31.2395 3.09827L29.983 6.56758H32.0173ZM22.3841 9.97708C21.3071 9.91727 20.29 9.73782 19.6916 9.43874L20.1703 7.46482C20.7088 7.70408 21.367 8.06298 22.5636 8.00316C23.2816 8.00316 23.9996 7.7639 23.9996 7.10592C23.9996 6.74703 23.7005 6.44795 22.803 5.96942C21.8456 5.55071 20.649 4.77311 20.7088 3.45716C20.7088 1.66269 22.3841 0.406555 24.7176 0.406555C25.6151 0.406555 26.393 0.645819 26.9315 0.825266L26.5126 2.79919C25.4356 2.32066 24.5381 2.32066 24.1791 2.38048C23.4611 2.44029 23.162 2.79919 23.162 3.15808C23.162 4.23477 26.5126 4.3544 26.5126 6.80685C26.5126 8.72095 24.8373 9.97708 22.3841 9.97708ZM16.6401 0.586003L12.751 9.79764H10.238L8.38313 2.44029C8.26347 2.02158 8.1438 1.84213 7.7848 1.66269C7.18647 1.30379 6.28896 1.06453 5.45129 0.885082L5.51113 0.586003H9.51996C10.0585 0.586003 10.5371 0.944898 10.6568 1.54306L11.674 6.86666L14.1271 0.586003H16.6401Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93904 26.7252C10.1784 26.9047 10.5972 27.0243 11.016 27.0243C11.5545 27.0243 11.9135 26.7851 11.9135 26.3663C11.9135 26.0673 11.6742 25.828 11.0759 25.6486C10.298 25.3495 9.81937 24.9308 9.81937 24.2728C9.81937 23.4952 10.4177 22.9568 11.4349 22.9568C11.9734 22.9568 12.3324 23.0765 12.5717 23.1363L12.3324 23.8541C12.2127 23.7943 11.8537 23.6148 11.4349 23.6148C10.8964 23.6148 10.657 23.9139 10.657 24.213C10.657 24.5719 10.9562 24.6915 11.5545 24.9308C12.3324 25.2897 12.7512 25.6486 12.7512 26.3065C12.7512 27.0841 12.2127 27.7421 11.016 27.7421C10.4775 27.7421 9.99887 27.5627 9.69971 27.443L9.93904 26.7252Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0421 25.5887H14.3069V26.9645H16.2814V27.6823H13.4692V23.0167H16.1617V23.6746H14.3069V24.9308H16.0421V25.5887Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.29 27.5028C20.0506 27.6225 19.6318 27.7421 19.0335 27.7421C17.5975 27.7421 16.7 26.7851 16.7 25.4093C16.7 23.8541 17.777 22.9568 19.1531 22.9568C19.7515 22.9568 20.1105 23.0765 20.29 23.1363L20.1104 23.7943C19.9309 23.7345 19.5719 23.6148 19.2129 23.6148C18.2556 23.6148 17.5975 24.213 17.5975 25.3495C17.5975 26.3663 18.1958 27.0243 19.2129 27.0243C19.5121 27.0243 19.9309 26.9645 20.1104 26.8449L20.29 27.5028Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7859 23.0167V25.7084C21.7859 26.6056 22.1449 27.0243 22.7433 27.0243C23.3416 27.0243 23.7006 26.6056 23.7006 25.7084V23.0167H24.5383V25.6486C24.5383 27.0841 23.8203 27.7421 22.6834 27.7421C21.6064 27.7421 20.8884 27.144 20.8884 25.6486V23.0167H21.7859Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.436 23.0765C25.795 23.0167 26.2737 22.9568 26.7524 22.9568C27.4105 22.9568 27.8294 23.0765 28.1884 23.3157C28.4277 23.555 28.5474 23.8541 28.5474 24.2728C28.5474 24.871 28.1884 25.2897 27.7097 25.4691C28.0687 25.5887 28.2482 25.8878 28.3679 26.3663C28.5474 26.9645 28.667 27.5028 28.7269 27.6823H27.8892C27.7695 27.5028 27.7097 27.144 27.5302 26.5458C27.4105 25.9476 27.231 25.7682 26.7524 25.7682H26.2737V27.6823H25.436V23.0765ZM26.2737 25.1102H26.8122C27.3507 25.1102 27.7097 24.8111 27.7097 24.3326C27.7097 23.8541 27.3507 23.6148 26.8122 23.6148C26.533 23.6148 26.3535 23.6148 26.2737 23.6148V25.1102Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8978 25.5887H30.1626V26.9645H32.1371V27.6823H29.325V23.0167H32.0175V23.6746H30.1626V24.9308H31.8978V25.5887Z"
        fill="#FFFFFE"
      />
    </svg>
  );
};
