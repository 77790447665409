import styles from "./Person.module.scss";

interface IProps {
  littleIcon?: boolean;
}

export const Person = (props: IProps) => {
  return (
    <div className={styles.container}>
      <svg
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7986 14.8903C18.8116 14.8903 22.0648 11.6371 22.0648 7.62411C22.0648 3.6111 18.8116 0.35791 14.7986 0.35791C10.7855 0.35791 7.53235 3.6111 7.53235 7.62411C7.53235 11.6371 10.7855 14.8903 14.7986 14.8903Z"
          fill="#3F260A"
        />
        <path
          d="M29.3309 31.2393V23.9731C29.3309 22.8832 28.786 21.7933 27.8777 21.0666C25.8795 19.4317 23.3363 18.3418 20.7931 17.6152C18.9766 17.0702 16.9784 16.7069 14.7985 16.7069C12.8003 16.7069 10.8021 17.0702 8.8039 17.6152C6.26073 18.3418 3.71756 19.6134 1.71935 21.0666C0.811079 21.7933 0.266113 22.8832 0.266113 23.9731V31.2393H29.3309Z"
          fill="#3F260A"
        />
      </svg>
    </div>
  );
};
