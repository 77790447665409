export const ApplePay = () => {
  return (
    <svg viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6627 11.6039C11.6029 10.9458 11.902 10.2876 12.261 9.86875C12.6799 9.39009 13.3979 9.03109 13.9962 8.97125C14.056 9.68925 13.7569 10.3474 13.3979 10.8261C12.979 11.3048 12.3209 11.6638 11.6627 11.6039Z"
        fill="#3F260A"
      />
      <path
        d="M16.0304 12.8594C15.9706 12.9192 14.7739 13.637 14.8338 15.0726C14.8338 16.8072 16.3296 17.3456 16.3296 17.4054C16.3296 17.4054 16.0903 18.183 15.5518 19.0204C15.0731 19.6784 14.5944 20.3962 13.8166 20.3962C13.0388 20.3962 12.7995 19.9775 11.9618 19.9775C11.0643 19.9775 10.7651 20.3962 10.0471 20.3962C9.32908 20.456 8.73075 19.6784 8.25208 18.9606C7.29475 17.525 6.57678 14.9529 7.53411 13.2183C8.07261 12.3211 8.97011 11.7827 9.92745 11.7827C10.6454 11.7827 11.3634 12.2612 11.7823 12.2612C12.2011 12.2612 13.0388 11.6631 13.9363 11.7229C14.2953 11.7827 15.3723 11.9023 16.0304 12.8594Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2703 9.80859C25.3644 9.80859 26.7406 11.1844 26.7406 13.2779C26.7406 15.3116 25.3046 16.7472 23.2703 16.7472H20.9966V20.3362H19.3811V9.80859H23.2703ZM20.9368 15.3715H22.7916C24.2276 15.3715 25.0653 14.5939 25.0653 13.2779C25.0653 11.9021 24.2276 11.1844 22.8514 11.1844H20.9368V15.3715Z"
        fill="#3F260A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1594 18.1231C27.1594 16.8072 28.1765 16.0296 30.0912 15.9099L32.1255 15.7903V15.1921C32.1255 14.3547 31.5272 13.8762 30.5699 13.8762C29.7322 13.8762 29.1339 14.2949 29.0142 14.9529H27.5184C27.5782 13.5771 28.8347 12.5602 30.5699 12.5602C32.4247 12.5602 33.6812 13.5771 33.6812 15.0725V20.3363H32.1854V19.0802H32.1255C31.7067 19.9176 30.7494 20.3961 29.7322 20.3961C28.2364 20.3961 27.1594 19.4989 27.1594 18.1231ZM32.1255 17.4651V16.867L30.2707 16.9866C29.2536 17.0464 28.7151 17.4651 28.7151 18.1231C28.7151 18.7213 29.3134 19.14 30.1511 19.14C31.2879 19.14 32.1255 18.482 32.1255 17.4651Z"
        fill="#3F260A"
      />
      <path
        d="M35.0576 23.1474V21.8913C35.1773 21.8913 35.4167 21.8913 35.5363 21.8913C36.2543 21.8913 36.6731 21.5922 36.9125 20.8146L37.0322 20.336L34.2798 12.6796H36.015L37.9297 18.9005L39.8443 12.6796H41.5197L38.6476 20.7548C37.9895 22.609 37.2715 23.2072 35.656 23.2072C35.5363 23.2072 35.1773 23.2072 35.0576 23.1474Z"
        fill="#3F260A"
      />
      <path
        d="M4.60227 0.836182H43.9726C45.7676 0.836182 47.1438 2.27176 47.1438 4.00642V27.9327C47.1438 29.6674 45.7676 31.0432 43.9726 31.0432H4.60227C2.8671 31.0432 1.49097 29.6674 1.49097 27.9327V4.00642C1.49097 2.27176 2.8671 0.836182 4.60227 0.836182Z"
        stroke="#3F260A"
        strokeWidth="1.2"
      />
    </svg>
  );
};
